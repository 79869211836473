import React, { useState, useEffect } from "react";
import { useUser } from "../../../components/UserContext";
import { animated, useTransition } from "react-spring";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { useParams } from "react-router-dom";

interface DataDriverOfTheDay {
  driver_of_the_day_votes: never[];
  event_drivers: never[];
  race_statements: never[];
  user_voted: boolean;
  user_wrote: boolean;
}
const DriverOfTheDay = ({
  data,
  setLoading,
}: {
  data: DataDriverOfTheDay;
  setLoading: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { user } = useUser() as any;
  let { grandprix } = useParams();
  const [valid, setValid] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [checkedDriver, setCheckedDriver] = useState(-1);

  const transitionVoting = useTransition(valid, {
    from: { background: "#dddddd" },
    enter: {
      background: valid ? "#1dc9b8" : "#dddddd",
      color: valid ? "#ffffff" : "#000000",
    },
  });
  useEffect(() => {
    setCheck1(
      (user === null && data.driver_of_the_day_votes.length === 0) ||
        (data.driver_of_the_day_votes.length === 0 &&
          data.event_drivers.filter((driver: any) => driver.username === user)
            .length === 0)
    );
    setCheck2(
      data.user_voted === true ||
        user === null ||
        data.event_drivers.filter((driver: any) => driver.username === user)
          .length === 0
    );
    var div_dotd = document.getElementById("driverofthedaychart");
    if (div_dotd) {
      //First, we remove the previous chart in case they exist
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root != null && root.dom.id == `driverofthedaychart`) {
          root.dispose();
        }
      });
      var root = am5.Root.new(`driverofthedaychart`);
      drawDriverOfTheDay(root, data.driver_of_the_day_votes);
    }
  }, [grandprix, data, user, window.localStorage.getItem("theme")]);

  const vote = () => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null || checkedDriver < 1) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/voteDriverOfTheDay`,
        {
          name: grandprix,
          voting_user: user,
          voted_user: checkedDriver,
          token: JSON.parse(token).token,
        }
      )
      .then(({ data }) => {
        if (data === "OK") setLoading(true);
        toast.success(`${t("VoteOK")}`);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  };

  const drawDriverOfTheDay = (
    root: am5.Root,
    data_votes: {
      username: string;
      team_id: number;
      votes: number;
      code: string;
    }[]
  ) => {
    //Colours deppending on the drak/light mode (labels and grid)
    const defaultColour =
      window.localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#000000";
    // Set themes
    const myTheme = am5.Theme.new(root);
    myTheme.rule("Label").setAll({
      fill: am5.color(defaultColour),
    });
    myTheme.rule("Grid").setAll({
      stroke: am5.color(defaultColour),
    });
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Material.new(root),
      myTheme,
    ]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
      })
    );

    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "driver",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.set("visible", false);

    let yRenderer = am5xy.AxisRendererY.new(root, {});
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        maxPrecision: 0,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2],
    });

    // Create series
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "votes",
        sequencedInterpolation: true,
        categoryXField: "driver",
        tooltip: am5.Tooltip.new(root, { dy: -25, labelText: "{valueY}" }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    });

    series.columns.template.adapters.add("fill", (fill: any, target: any) => {
      return chart.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add(
      "stroke",
      (stroke: any, target: any) => {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      }
    );

    // Set data
    let data: {
      driver: string;
      votes: number;
      bulletSettings: { src: string };
    }[] = [];
    data_votes.forEach((driver, index) => {
      data.push({
        driver: driver.username,
        votes: driver.votes,
        bulletSettings: {
          src:
            driver.team_id == null
              ? `/assets/Flags/${driver.code}.png`
              : `/assets/Teams/${driver.team_id}.png`,
        },
      });
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Picture.new(root, {
          templateField: "bulletSettings",
          width: 40,
          height: 40,
          centerX: am5.p50,
          centerY: am5.p50,
          shadowColor: am5.color(0x000000),
          shadowBlur: 4,
          shadowOffsetX: 4,
          shadowOffsetY: 4,
          shadowOpacity: 0.6,
        }),
      });
    });
    xAxis.data.setAll(data);
    series.data.setAll(data);

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);
  };
  return (
    <div className="driver-of-the-day-container frame-turqoise">
      <label className="frame-title">{t("DriverOfTheDay").toUpperCase()}</label>

      <div id="no-votes-message" style={{ display: check1 ? "block" : "none" }}>
        {t("NoVotes")}
      </div>

      <div
        id="driverofthedaychart"
        style={{
          width: "100%",
          height: "calc(100vh - 280px)",
          display: !check1 && check2 ? "block" : "none",
        }}
      ></div>

      <div
        id="voting-driver-of-the-day"
        style={{ display: !check1 && !check2 ? "block" : "none" }}
      >
        <label>{t("VotingDriverOfTheDay")}</label>
        <div id="voting-drivers-container">
          {data?.event_drivers.map(
            (driver: { username: string; id: number }) => {
              return (
                <label key={driver.id} className="container-checkbox">
                  {driver.username}
                  <input
                    type="checkbox"
                    checked={checkedDriver === driver.id}
                    onChange={() => {
                      setCheckedDriver(driver.id);
                      setValid(true);
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              );
            }
          )}
        </div>
        {transitionVoting((style) => {
          return (
            <animated.button
              style={style}
              className="post-race-button"
              onClick={vote}
            >
              {t("Vote").toUpperCase()}
            </animated.button>
          );
        })}
      </div>
    </div>
  );
};

export default DriverOfTheDay;
