import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../../../components/UserContext";
import { animated, useTransition } from "react-spring";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Material from "@amcharts/amcharts5/themes/Material";
import { useParams } from "react-router-dom";

interface DataDriverOfTheDay {
  driver_of_the_day_votes: never[];
  event_drivers: never[];
  race_statements: never[];
  user_voted: boolean;
  user_wrote: boolean;
}
const DriverOfTheDay = ({
  data,
  setLoading,
}: {
  data: DataDriverOfTheDay;
  setLoading: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const { user } = useUser() as any;
  let { grandprix } = useParams();
  const [valid, setValid] = useState(false);
  const [statementText, setStatementText] = useState("");

  const transitionStatement = useTransition(statementText, {
    enter: {
      background: statementText !== "" ? "#1dc9b8" : "#dddddd",
      color: statementText !== "" ? "#ffffff" : "#000000",
    },
  });
  useEffect(() => {
    var div_statements = document.getElementById(`statementschart`);
    if (div_statements) {
      //First, we remove the previous chart in case they exist
      am5.array.each(am5.registry.rootElements, function (root) {
        if (root != null && root.dom.id === `statementschart`) {
          root.dispose();
        }
      });
      var root = am5.Root.new(`statementschart`);
      drawStatements(root, data.race_statements);
    }
  }, [grandprix, data, user, window.localStorage.getItem("theme")]);

  const statement = () => {
    let token = window.localStorage.getItem("userLogged");
    if (token == null || statementText === "") return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/addStatement`,
        {
          name: grandprix,
          user: user,
          message: statementText,
          token: JSON.parse(token).token,
        }
      )
      .then(({ data }) => {
        if (data === "OK") setLoading(true);
        toast.success(`${t("StatementOK")}`);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  };

  const drawStatements = (
    root: am5.Root,
    statements: { username: string; statement: string }[]
  ) => {
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Material.new(root),
    ]);

    // Add series
    var series = root.container.children.push(
      am5wc.WordCloud.new(root, {
        maxCount: 10,
        minWordLength: 2,
        minFontSize: am5.percent(2),
        maxFontSize: am5.percent(3),
        angles: [0],
      })
    );

    // Configure labels
    series.labels.template.setAll({
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      fontFamily: "Century Gothic",
    });

    series.labels.template.setup = function (label) {
      label.set("text", `{category}\n\n[bold]{username}[/]`);
      label.set(
        "background",
        am5.RoundedRectangle.new(root, {
          fillOpacity: 1,
          fill: am5.color("#7cede2"),
        })
      );
    };

    //Set data
    let width: number = parseInt(ref.current ? ref.current.offsetWidth : 0);
    let data: {
      category: string;
      username: string;
      value: number;
    }[] = [];
    statements.forEach((statement) => {
      //We transform the statement text into lines
      data.push({
        category: wrap(statement.statement, 35),
        username: statement.username,
        value: 1,
      });
    });
    series.data.setAll(data);
  };

  const wrap = (s: string, w: number) =>
    s.replace(
      new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"),
      "$1\n"
    );

  return (
    <div ref={ref} className="statements-container frame-turqoise">
      <label className="frame-title">{t("Statements").toUpperCase()}</label>
      {data.race_statements.length === 0 ? (
        <div id="no-statements-message">{t("NoStatements")}</div>
      ) : (
        <div
          id="statementschart"
          style={{
            width: "100%",
            height:
              data.user_wrote === false && user != null
                ? "calc(100vh - 380px)"
                : "calc(100vh - 280px)",
          }}
        ></div>
      )}
      {data.user_wrote === false &&
        user != null &&
        data.event_drivers.filter((driver: any) => driver.username === user)
          .length > 0 && (
          <div className="write-statement-container flex">
            <textarea
              onChange={(e) => setStatementText(e.target.value)}
              placeholder={t("WriteStatement")}
            ></textarea>
            {transitionStatement((style) => {
              return (
                <animated.button
                  style={style}
                  className="post-race-button"
                  onClick={statement}
                >
                  {t("Post").toUpperCase()}
                </animated.button>
              );
            })}
          </div>
        )}
    </div>
  );
};

export default DriverOfTheDay;
