import axios from "axios";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/style/findings.css";
import { useUser } from "../../components/UserContext";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import { Link, useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import ClaimForm from "./findings/ClaimForm";
import ClaimsView from "./findings/ClaimsView";
import ClaimsPenalties from "./findings/ClaimsPenalties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { transformHour } from "../../utils/DateTransformations";

const Findings = () => {
  const { t } = useTranslation();
  //document.title = "LVF1 - " + t("Findings");
  let { grandprix } = useParams();
  const { user } = useUser() as any;
  const [sessionsPlayed, setSessionPlayed] = useState<number[]>([]);
  const [claim, setClaim] = useState<ClaimPost>({
    driver: "",
    session: "",
    minute: "",
    observations: "",
    updating: -1,
  });

  const [drivers, setDrivers] = useState<{ user: string; id: number }[]>([]);
  const [claims, setClaims] = useState<ClaimGP[]>([]);
  const [penalties, setPenalties] = useState<PenaltiesGP[]>([]);
  const [dateText, setDateText] = useState([""]);
  const [postAvailable, setPostAvailable] = useState(false);
  const [updateClaims, setUpdateClaims] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const getDate = (date: string, transf: { day: number; time: string }) => {
    const inputDate = date.slice(0, 10);

    const dateString = `${inputDate}T${transf.time}:00Z`;
    setDateText([dateString]);

    const parsedDate = moment(dateString).add(transf.day, "days");

    moment.tz.setDefault("Europe/London");
    const london_date = moment();
    const timeLimit = `${inputDate} ${transf.time}:00`;

    const isBefore = london_date.isBefore(
      moment(timeLimit, "YYYY-MM-DD HH:mm:ss")
    );

    return isBefore;
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getFindings`,
        {
          params: { gp: grandprix },
        }
      )
      .then(({ data }: { data: Findings }) => {
        if (data.qualy_results.length === 0) setIsExternal(true);
        setSessions(data);
        setDriversArray(data);

        setPostAvailable(getDate(data.date, data.time));

        setClaims(data.claims_gp);
        setPenalties(data.penalties_gp);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [grandprix, updateClaims]);

  const setDriversArray = (data: Findings) => {
    let drivers_: { user: string; id: number }[] = [];
    drivers_ = data.qualy_results.reduce((previousValue, currentValue) => {
      const filtered = previousValue.filter(
        (driver) => driver.id === currentValue.driver_id
      );
      if (filtered.length === 0) {
        previousValue.push({
          user: currentValue.username,
          id: currentValue.driver_id,
        });
      }
      return previousValue;
    }, drivers_);

    drivers_ = data.race_results.reduce((previousValue, currentValue) => {
      const filtered = previousValue.filter(
        (driver) => driver.id === currentValue.driver_id
      );
      if (filtered.length === 0) {
        previousValue.push({
          user: currentValue.username,
          id: currentValue.driver_id,
        });
      }
      return previousValue;
    }, drivers_);
    drivers_.sort((a, b) => a.user.localeCompare(b.user));
    setDrivers(drivers_);
  };
  const setSessions = (data: Findings) => {
    //We get the array of sessions played
    let array_sessions: number[] = [];
    //Q2
    const isQ2 = data.qualy_results.filter(
      (q: { q2_time: string | null }) => q.q2_time !== null
    );
    if (isQ2.length > 0) array_sessions.push(-1);
    //Q3
    array_sessions.push(0);
    //Races
    data.race_results.forEach((result: { type_race: number }) => {
      if (!array_sessions.includes(result.type_race))
        array_sessions.push(result.type_race);
    });
    setSessionPlayed(array_sessions);
  };

  const getSessionName = (session: number) => {
    const moreThanOneRaces = sessionsPlayed.filter((session) => session > 1);
    if (session === -1) return "Q2";
    else if (session === 0) return "Q3";
    else if (moreThanOneRaces.length === 0 && session === 1) return t("Race");
    else if (session === 1) return "R1 (MAIN)";
    else if (session === 2) return "R2 (SPRINT)";
    else return `R${session}`;
  };

  const update = (claim_: ClaimGP) => {
    setClaim({
      ...claim,
      session: claim_.session.toString(),
      driver: claim_.driver_id.toString(),
      minute: claim_.time,
      observations: claim_.description,
      updating: claim_.claim_id,
    });
  };

  if (grandprix == null) return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={9} />
      {!isExternal && (
        <Link
          to={t("routes:DriversLicenses")}
          className="driver full-width block text-center dirver-license-link"
        >
          <b>
            <FontAwesomeIcon icon={faIdCard} /> {t("ClickToDriversLicenses")}
          </b>
        </Link>
      )}
      <div id="findings-div" className="flex">
        {!isExternal && postAvailable ? (
          <>
            <ClaimForm
              user={user}
              dateText={dateText}
              sessionsPlayed={sessionsPlayed}
              drivers={drivers}
              getSessionName={getSessionName}
              claim={claim}
              setClaim={setClaim}
              grandprix={grandprix}
              setUpdateClaims={setUpdateClaims}
            />
            <ClaimsView
              user={user}
              claims={claims}
              getSessionName={getSessionName}
              setClaims={setClaims}
              update={update}
              setUpdateClaims={setUpdateClaims}
              grandprix={grandprix}
            />
          </>
        ) : (
          <ClaimsPenalties
            sessionsPlayed={sessionsPlayed}
            getSessionName={getSessionName}
            penalties={penalties}
            claims={claims}
          />
        )}
      </div>
    </div>
  );
};

export default Findings;
