import "../../assets/style/grandprix_tables.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import LoadingContent from "../../components/LoadingContent";
import GrandPrixRecord from "../../components/GrandPrix/GrandPrixRecord";
import { useTranslation } from "react-i18next";

const RaceLivetiming = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    { server_timing: ServerTiming; server_live: CircleDriver[] }[][]
  >([]);
  let { grandprix } = useParams();
  const [gp, setGP] = useState<
    {
      circuit_id: number;
      date: Date;
      gp_race_event_id: number;
      type_race: number;
      type_races: number;
      laps: number;
      type_championship: number;
      game_id: number;
    }[]
  >([]);
  const [session, setSession] = useState("P1");

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getRaceLivetiming`,
        {
          params: {
            name: grandprix,
            session,
          },
        }
      )
      .then(({ data }) => {
        setData(data.livetiming_data);
        setGP(data.gp_data);
        setLoading(false);
        setTimeout(() => {
          //We remove gs
          const gElements = document.getElementsByClassName("cirdriver");
          while (gElements.length > 0) {
            gElements[0].parentNode?.removeChild(gElements[0]);
          }
        }, 500);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, [grandprix, loading, window.localStorage.getItem("theme"), session]);

  if (loading) return <LoadingContent />;
  if (grandprix == null || data.length == 0) return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={11} />
      <div className="race-livetiming-container">
        {data[0][0].server_timing.trackdata_name === "" && (
          <select
            className="form-control"
            onChange={(e: any) => {
              setSession(e.target.value);
            }}
          >
            {Array.from({ length: gp[0].laps }, (_, index) => index + 1).map(
              (index) => {
                return (
                  <option value={`P${index}`} key={index}>{`${t(
                    "Session"
                  )} ${index}`}</option>
                );
              }
            )}
          </select>
        )}
        {data.map((data_race, index_d) => {
          return (
            <GrandPrixRecord
              data_race={data_race}
              gp={gp[index_d]}
              length={data.length}
              key={index_d}
              session={session}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RaceLivetiming;
